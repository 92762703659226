body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(hsla(240, 50%, 80%, .5), hsla(240, 50%, 80%, 0));
  animation: bg-move 60s infinite;
  will-change: background-color;
  overflow: hidden;
}

#root {
  height: 100%;
  color: #fff;
  font-size: 32px;
  font-family: PT Sans, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 200;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
}

@keyframes bg-move {
  0%, 100% {
    background-color: hsl(210, 40%, 60%);
  }
  50% {
    background-color: hsl(300deg, 40%, 60%);
  }
}